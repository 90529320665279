<template>
  <div>

    <p v-html="page.inleiding"></p>

    <v-layout pa-2 class="question" mt-5 wrap>
      <v-flex xs12>{{ aantalVraag.omschrijving }}</v-flex>
      <v-flex xs12>
        <v-slider max="40" v-model="aantalVraag.reactie" thumb-label="always">
        </v-slider>
      </v-flex>
    </v-layout>
<br>
    <p>Verdeel uw ervaringsjaren over onderstaande categorieën:</p>

    <v-layout
      pa-2
      class="question"
      mt-5
      wrap
      v-for="(v, index) in silderSubVragen"
      :key="index"
    >
      <v-flex xs12 sm7>
        <v-btn icon color="#989898" @click="getToelichting(v.id)">
          <v-icon>mdi-information</v-icon> </v-btn
        >{{ v.omschrijving }}</v-flex
      >
      <v-flex xs12 pl-3 sm5>
        <v-slider
          :key="index"
          thumb-label="always"
          :max="maximus"
          v-model="v.reactie"
        >
        </v-slider>
      </v-flex>
    </v-layout>

    

    <v-dialog v-model="dialog" width="500">
      <v-card v-if="vraagToelichting">
        <v-card-title class="text-h5 grey lighten-2">
          {{vraagToelichting.omschrijving}}
        </v-card-title>

        <v-card-text>
            <div class="ml-2" v-html="vraagToelichting.toelichting"></div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false"> sluiten </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>



<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Ervaring",
  props: ["page"],
  data: () => ({
    aantalVraag: null,
    sliderVraag: null,
    silderSubVragen: [],
    vraagToelichting: null,
    dialog: false,
  }),
  methods: {
    ...mapActions(["updateAntwoorden"]),

    getToelichting(vraagId) {
      this.vraagToelichting = this.$store.getters.getVraag(vraagId);
      this.dialog = true;
    },

    submitNext() {
      let res = [];

      res.push({ id: this.aantalVraag.id, reactie: this.aantalVraag.reactie });

      const rsub = this.silderSubVragen.map((m) => {
        return { vraag_id: m.id, reactie: m.reactie };
      });

      res.push({ id: this.sliderVraag.id, reactie: rsub });

      this.updateAntwoorden(res);
    },
  },
  computed: {
    ...mapGetters({
      vragenlijst: "vragenlijst",
    }),
    maximus() {
      return (this.aantalVraag && this.aantalVraag.reactie) || 0;
    },
    teBesteden() {
      let w = 0;

      this.silderSubVragen.forEach((fe) => {
        w += fe.reactie;
      });

      return this.maximus - w;
    },
    pageValid() {
      return this.aantalVraag && this.aantalVraag.reactie > 0;
    },
  },
  watch: {
    pageValid: function (val) {
      this.$emit("pageValidChanged", val);
    },

    page: {
      immediate: true,
      handler(nv) {
        if (nv !== null) {
          this.aantalVraag = this.vragenlijst.find((f) => f.id === 107);

          this.sliderVraag = this.vragenlijst.find((f) => f.id === 94);

          this.silderSubVragen = this.vragenlijst
            .filter((f) => f.soort === "uitklap_form")
            .map((m) => {
              let r = null;

              if (this.sliderVraag.reactie != null) {
                r = this.sliderVraag.reactie.find((f) => f.vraag_id === m.id);
              }

              return {
                id: m.id,
                omschrijving: m.omschrijving,
                reactie: (r && r.reactie) || 0,
              };
            });
        }
      },
    },
  },
};
</script>

<style scoped>
.question {
  background-color: #f0f0f0;
}

p {
  color : black;
}

</style>